@import '~assets/styles/vars';

.leftWrap {
  flex-wrap: nowrap;

  @media all and (min-width: $breakpoint-sm) {
    width: calc(100% - 180px);
  }
}

.train {
  text-transform: capitalize;
}

.removeBtn {
  margin-top: $size-md;
  @media screen and (min-width: $breakpoint-sm) {
    margin: 0;
  }
}
